import React, { Component } from 'react';

import './NotFound.css';

export default class NotFound extends Component {
	render() {
		return (
			<div className="notfound-root-div">			
				<h1>
					<i>Page Not Found</i>
     		   </h1>
			</div>
		);
	}
}